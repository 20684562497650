import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  SerializeFrom,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { Suspense, useEffect, useState } from "react";
import { AuthenticityTokenProvider } from "remix-utils/csrf/react";
import { ExternalScripts } from "remix-utils/external-scripts";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { GlobalErrorBoundary } from "~/components/error-boundary";
import Toaster from "~/components/Toaster";
import { SEO_META_DEFAULT_CONFIG } from "~/config/seo.config";
import globalStylesheetUrl from "~/styles/global.css?url";
import { commitCsrf } from "~/utils/csrf.server";
import { getHoneypotProps } from "~/utils/honeypot.server";
import { combineHeaders } from "~/utils/misc";
import { useNonce } from "~/utils/nonce";
import { getSeoMeta } from "~/utils/seo";
import { useToast } from "~/utils/toast";
import { getToastFromRequest } from "~/utils/toast.server";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AppContextProvider } from "~/contexts/AppContext";

dayjs.locale("fr");
dayjs.extend(utc);

export const meta: MetaFunction = (metaArgs) => {
  return getSeoMeta(metaArgs, {
    title: SEO_META_DEFAULT_CONFIG.title,
    description: SEO_META_DEFAULT_CONFIG.description,
  });
};

export const links: LinksFunction = () => [
  {
    rel: "icon",
    href: "/favicons/favicon-32x32.png",
    type: "image/png",
    sizes: "32x32",
  },
  {
    rel: "icon",
    href: "/favicons/favicon-16x16.png",
    type: "image/png",
    sizes: "16x16",
  },
  {
    rel: "alternate icon",
    href: "/favicon.ico",
    sizes: "48x48",
  },
  {
    rel: "apple-touch-icon",
    href: "/favicons/apple-touch-icon.png",
    type: "image/png",
  },
  {
    rel: "manifest",
    href: "/site.webmanifest",
    crossOrigin: "use-credentials",
  },
  {
    rel: "stylesheet",
    href: globalStylesheetUrl,
  },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { toast, toastCookieHeaders } = await getToastFromRequest(request);
  const { csrfToken, csrfCookieHeaderContent } = await commitCsrf();
  const honeypotProps = getHoneypotProps();

  return json(
    { toast, csrfToken, honeypotProps },
    {
      headers: combineHeaders(
        toastCookieHeaders,
        csrfCookieHeaderContent
          ? new Headers({ "set-cookie": csrfCookieHeaderContent || "" })
          : null
      ),
    }
  );
};

function Document({
  children,
  nonce,
}: {
  children: React.ReactNode;
  nonce: string;
}) {
  return (
    <html lang="fr" className={`h-full overflow-x-hidden`}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex h-full flex-col text-gray-darkest">
        {/* App */}
        {children}

        {/* Scripts */}
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <ExternalScripts />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  useToast(data.toast);
  return (
    <Document nonce={nonce}>
      <Suspense
        fallback={
          // <LoadingSpinner className="mx-auto my-32" />
          null
        }
      >
        <AppContextProvider>
          <Outlet />
        </AppContextProvider>
      </Suspense>
      <Toaster richColors />
    </Document>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
      <HoneypotProvider {...data.honeypotProps}>
        <App />
      </HoneypotProvider>
    </AuthenticityTokenProvider>
  );
}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  const nonce = useNonce();
  return (
    <Document nonce={nonce}>
      <GlobalErrorBoundary />
    </Document>
  );
}

export function useRootMatchData() {
  const matches = useMatches();
  const match = matches.find((m) => m.id === "root");
  const [data, setData] = useState(match?.data || null);
  useEffect(() => {
    if (match && match.data) {
      setData(match.data);
    }
  }, [match]);
  return data as SerializeFrom<typeof loader> | null;
}
